html,
body,
* {
	cursor: url('./ui-cursor-smol.png') 1 1, pointer !important;

	button:hover,
	button:active {
		cursor: url('./ui-cursor-active-highlight-smol.png') 1 1, pointer !important;
	}
	
	canvas {
		cursor: url('./cursor-smol.png') 16 16, pointer !important;
	}
}

.ActiveCursor() {
	cursor: url('./ui-cursor-active-highlight-smol.png') 1 1, pointer !important;
}
